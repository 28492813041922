import React from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import { GlobalStyles } from "./components/styles/Global";
import { ThemeProvider } from "styled-components";
import { theme } from "./components/styles/Theme";
// import HeroSimplex from "./components/Hero/Hero_simplex";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Map from "./components/Map/Map";
import RGButton from "./components/RGButton/RGButton";
import Message from "./components/Message/Message";
import './general.css'
import {
  HeroImage,
  MainDetails,
  MainHero,
  MainTitlte,
} from "./components/styles/Hero.styled";
import roselogo from "./assets/roselogo_new2.png"
/////////////////////////////////////////////////////////////////////////////////////////////////
// calendar system
var aclink = "https://app.acuityscheduling.com/schedule.php?owner=33804352&template=class&ref=booking_button"
var gclink = "https://gymcatch.com/app/provider/5622/events"
var talink = "https://rosegarden-yoga.taramala.com/classes"
var clink = talink
/////////////////////////////////////////////////////////////////////////////////////////////////
// Link to online zoom class
var ZOOM = "https://meet.google.com/ces-thfp-vdj"
// ZOOM = "CANCELLED"
// ZOOM = "At the Rose Garden - no zoom"
var CLASSDATE = "Sunday 12/22/2024"
var CLASSTIME = "6:00pm"
var MESSAGE = "UPDATE: " + CLASSDATE + " @ " + CLASSTIME + " - **SESSION CANCELLED** - No Zoom class."
// MESSAGE = "UPDATE: Today's 12/29/24 park Moon Sequence class is cancelled."
// MESSAGE = "NO MORE ZOOM"
// var MESSAGE = "UPDATE: Tuesday 2/13 6pm Deep Stretch online zoom class CANCELLED." 
// MESSAGE = "Week of May 13: Deep Stretch Wednesday @ 6p - No class on Tuesday and Thursday"
// MESSAGE = "Mother's Day Yoga - 5/12 Sunday @ 10a for Moon Sequence"
MESSAGE = "Join us at the Rose Garden Sundays @ 10a for Moon Sequence and Tuesdays @ 6p for Deep Stretch yoga. "
// MESSAGE += "<div className='message'>*** Thursday 10/31 will be our last <em>weekday</em> class of the year.  Join us for a Free abbreviated Deep Stretch class followed by a community appreciation gathering.  Optional BYOT (bring your own treat).</div>"
// MESSAGE += "<div className='message'>FYI, our Sunday 10am Moon Sequence class will continue throughout Fall and Winter at the Rose Garden.</div>"
// MESSAGE += "<div className='message'>Weekday Deep Stretch and Fusion Flow classes will be online beginning 11/5.</div>"
//MESSAGE = "Update: Tuesday Oct 22 @ 5:30p Deep Stretch is CANCELLED - See you Thursday @ 5:30p or Sunday @ 10a for Moon Sequence"
// MESSAGE = "Tuesday May 28 6p and Thursday May 30 6p classes are *cancelled* for SJHS graduation.  See you on Sunday!" 
var TABLEMSG =

    <tbody>
      <tr><td>Moon Sequence</td><td>Sundays @ 10a - Rose Garden</td></tr>
      <tr><td>Deep Stretch</td><td>Tuesdays @ 6p - Rose Garden</td></tr>
    </tbody>


// var WHATSNEW = "We are changing the class scheduling system. You can access the old gymcatch system <a style={{'color':'red'}} href={gclink}>here</a>."
// var WHATSNEW = "<div style='font-style:italic;font-size:1.5em'>******* Sunday 12/22 10a : CLASS CANCELLED *******</div>"
var WHATSNEW = "** New Year Day Yoga - 1/1 @ 11a at the Rose Garden. **"
WHATSNEW = "Tuesday Deep Stretch is now at 6p at the Rose Garden...weather permitting."
WHATSNEW = "Tuesday Rose Garden Deep Stretch class is ONLINE today!"
// var WHATSNEW = "Sunday 1/14 Moon Sequence class is cancelled.  See you next week!""
//"Sunday 1/19 Moon Sequence class is cancelled.  See you next week!"
/////////////////////////////////////////////////////////////////////////////////////////////////

// Simple Landing page
function Landing() {
  // var gclink = "https://gymcatch.com/app/provider/5622/events";
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="container">
        <MainHero>
          <MainDetails>
            <MainTitlte>
              <span style={{ color: "lightred", "opacity": ".8" }}>#RoseGarden.Yoga</span>
              <div className="myhero">
                <div style={{ "marginTop": "1px" }}>
                  <a href={clink} rel="noreferrer" target="_blank" style={{ "textDecoration": "none" }}>
                    <HeroImage src={roselogo} alt="main image" />
                  </a>
                </div>
                <div>
                  {WHATSNEW.length>0 &&  <Message message={WHATSNEW} />}
                  <RGButton link={clink} label="Class Schedule" bgcolor="#962e24" />
                  <Message message={MESSAGE} table={TABLEMSG} />
                </div>
              </div >
            </MainTitlte>

          </MainDetails>
          <div style={{ "margin": "auto", "textAlign": "center", "color": "#91201c", "fontStyle": "italic" }}>
            <a href="/about">More information</a>
          </div>
        </MainHero>

      </div>
    </ThemeProvider>)
}

// More information page
function Detail() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="container">
        <Hero gclink={gclink} aclink={aclink} />
        <About />
        <RGButton link={aclink} label="Class Schedule" />
        {WHATSNEW.length>0 &&  <Message message={WHATSNEW} />}
        <Map />
        <Footer />
      </div>
    </ThemeProvider>)
}
// Error redirects to landing
function ErrorBoundary() {
  return <Landing />;
}

function Venmo() {
  return(
  // window.location.replace(ZOOM)
  <ThemeProvider theme={theme}>
  <GlobalStyles />
  <div className="container" style={{ marginTop: 100, textAlign: "center" }}>
    Connect to Rose Garden Yoga online class:
  </div>
  <div className="container" style={{ marginTop: 20, textAlign: "center" }}>
    <a href={ZOOM} target="_blank" rel="noreferrer" >{ZOOM}</a>
  </div>
</ThemeProvider>)
}

function ZoomPage() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="container" style={{ marginTop: 100, textAlign: "center" }}>
        Connect to Rose Garden Yoga online class:
      </div>
      <div className="container" style={{ marginTop: 20, textAlign: "center" }}>
        <a href={ZOOM} target="_blank" rel="noreferrer" >{ZOOM}</a>
      </div>
    </ThemeProvider>)


}
// Router definition
const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorBoundary />
  },
  {
    path: "/about",
    element: <Detail />,
    errorElement: <ErrorBoundary />
  },
  {
    path: "/onlineclass",
    element: <ZoomPage />
  },
  {
    path: "/sunday",
    element: <ZoomPage />
  },
  {
    path: "/online",
    element: <Venmo />
  }
]);

// Application
function App() {
  return (
    <RouterProvider router={router} />
  );
}


export default App;
